import React, { useState } from 'react'
import { Box, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Sidenav from 'components/Sidenav'
import { useWindowWidth } from 'hooks/useWindowWidth'

const Navigation = (props) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false) 
  const width = useWindowWidth()

  const routes = [
    { name: 'Dashboard', path: '/' },
    { name: 'My Proposals', path: '/my-proposals' },
    { name: 'RFP Search & Management', path: '/rfp-search-and-management' },
    { name: 'Proposal Builder', path: '/proposal-builder' },
    { name: 'Help & Support', path: '/help-and-support' },
    { name: 'Settings', path: '/settings' },
  ]

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {width < 1200 && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Sidenav
          routes={routes}
          mobileDrawerOpen={mobileDrawerOpen}
          setMobileDrawerOpen={setMobileDrawerOpen}
          isSidebarCollapsed={isSidebarCollapsed} 
        />
        <Box
          sx={{
            flex: 3,
            backgroundColor: 'background.default',
            width: isSidebarCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 266px)',
            p: 2,
            overflowY: 'auto',
            height: '100vh',
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation
