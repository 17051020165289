import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    selectionDisabled,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead
      sx={{
        backgroundColor: '#f0f0f0',
      }}>
      <TableRow>
        {!selectionDisabled && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'Select All',
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              backgroundColor: '#BCDAF6',
              color: 'black',
              verticalAlign: 'top',
            }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  selectionDisabled: PropTypes.bool,
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, title, filterDisabled, selectionDisabled } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}>
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', fontWeight: 'bold', textAlign: 'center' }}
          variant="h6"
          id="tableTitle"
          align={filterDisabled && selectionDisabled ? 'center' : 'left'}
          component="div">
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip
          title="Delete"
          onClick={() =>
            alert(
              'You tried to delete a table item. This feature is still in progress.'
            )
          }>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : !filterDisabled ? (
        <Tooltip title="Filter list">
          <IconButton
            onClick={() =>
              alert(
                'You tried to filter the results. This feature is still in progress.'
              )
            }>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string,
  filterDisabled: PropTypes.bool,
  selectionDisabled: PropTypes.bool,
}

const EnhancedTable = (props) => {
  const {
    rows,
    rowCells,
    headCells,
    title,
    filterDisabled = false,
    selectionDisabled = false,
    paginationDisabled = false,
    size = 'medium',
    onRowClick,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    isLastPage,
  } = props

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(headCells[0]?.id || '')
  const [selected, setSelected] = useState([])
  const [pageState, setPageState] = useState(0)
  const [rowsPerPageState, setRowsPerPageState] = useState(10)
  const isExternalPagination =
    page !== undefined &&
    rowsPerPage !== undefined &&
    onPageChange &&
    onRowsPerPageChange
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    if (isExternalPagination) {
      onPageChange(event, newPage)
    } else {
      setPageState(newPage)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    if (isExternalPagination) {
      onRowsPerPageChange(event)
    } else {
      setRowsPerPageState(newRowsPerPage)
      setPageState(0)
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const sortedRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy))
  }, [rows, order, orderBy])
  const visibleRows = useMemo(() => {
    return sortedRows
  }, [sortedRows])
  const colSpan = headCells.length + (selectionDisabled ? 0 : 1)

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          filterDisabled={filterDisabled}
          selectionDisabled={selectionDisabled}
        />
        <TableContainer
          sx={{
            maxHeight: '100vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          }}>
          <Table
            sx={{ minWidth: 750 }}
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="tableTitle"
            size={size}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              selectionDisabled={selectionDisabled}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      if (!selectionDisabled) handleClick(event, row.id)
                      else if (onRowClick) onRowClick(row)
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    {!selectionDisabled && (
                      <TableCell padding="checkbox" scope="row" id={labelId}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {rowCells(row)}
                  </TableRow>
                )
              })}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={colSpan} align="center">
                    <Typography variant="body1">No records found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!paginationDisabled && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={(page + 1) * rowsPerPage - (rowsPerPage - rows.length)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ page: currentPage }) =>
              `Page ${currentPage + 1}`
            }
            nextIconButtonProps={{ disabled: isLastPage }}
            backIconButtonProps={{ disabled: page === 0 }}
          />
        )}
      </Paper>
    </Box>
  )
}

EnhancedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  rowCells: PropTypes.func.isRequired,
  headCells: PropTypes.array.isRequired,
  title: PropTypes.string,
  filterDisabled: PropTypes.bool,
  selectionDisabled: PropTypes.bool,
  paginationDisabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  onRowClick: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  isLastPage: PropTypes.bool,
}

export default EnhancedTable
