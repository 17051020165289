import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Skeleton,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import moment from 'moment'
import api from 'lib/api'
import CopyrightFooter from 'components/CopyrightFooter'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const getErrorMessage = (error, context = '') => {
  if (!error) return 'An unexpected error occurred. Please try again.'

  if (error.response) {
    const { status, data } = error.response

    switch (status) {
      case 400:
        if (data && data.message) {
          let errorMsg = data.message
          if (
            data.fields &&
            Array.isArray(data.fields) &&
            data.fields.length > 0
          ) {
            errorMsg += ` Missing fields: ${data.fields.join(', ')}.`
          }
          return context ? `${context}: ${errorMsg}` : errorMsg
        }
        return context ? `${context}: Bad request.` : 'Bad request.'
      case 401:
      case 403:
        return context
          ? `${context}: You do not have the necessary permissions to perform this action. Please contact support if you believe this is an error.`
          : 'You do not have the necessary permissions to perform this action.'
      case 404:
        return context
          ? `${context}: Resource not found.`
          : 'Resource not found.'
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
        return context
          ? `${context}: We're experiencing server issues. Please try again later.`
          : 'Server error. Please try again later.'
      default:
        return data?.message
          ? context
            ? `${context}: ${data.message}`
            : data.message
          : context
          ? `${context}: An error occurred during the operation.`
          : 'An error occurred during the operation.'
    }
  }

  if (error.message && error.message.includes('Network Error')) {
    return context
      ? `${context}: Unable to connect to our servers. Please check your internet connection and try again.`
      : 'Unable to connect to the server. Please check your internet connection and try again.'
  }

  return context
    ? `${context}: ${
        error.message || 'An unexpected error occurred. Please try again.'
      }`
    : error.message || 'An unexpected error occurred. Please try again.'
}

const MailingListTable = ({ emails, onDelete }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 300,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: '#ccc',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: '#f1f1f1',
        },
      }}>
      <Table aria-label="mailing list table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails.map((email, index) => (
            <TableRow key={index} hover>
              <TableCell>{email}</TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={() => onDelete(email)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const DashboardPage = () => {
  const theme = useTheme()
  const [emails, setEmails] = useState([])
  const tenantId = useSelector((state) => state.user.tenantId)
  const [chartData, setChartData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [mailingLoading, setMailingLoading] = useState(true)
  const [error, setError] = useState(null)
  const [mailingError, setMailingError] = useState(null)
  const [newEmail, setNewEmail] = useState('')
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  useEffect(() => {
    let isMounted = true
    const fetchUsageData = async () => {
      try {
        const response = await api.tenants.getMembershipUsage({ tenantId })
        const { date, tokens } = response.data
        const processedTokens = tokens.map((token) =>
          token === null ? 0 : token
        )

        if (isMounted) {
          setChartData({
            labels: date.map((d) => moment(d).format('MM/DD/YYYY')),
            datasets: [
              {
                label: 'Usage',
                data: processedTokens,
                borderColor: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.light,
                fill: true,
                tension: 0.4,
                pointRadius: 3,
                pointHoverRadius: 6,
              },
            ],
          })
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching membership usage:', error)
        if (isMounted) {
          const errorMsg = getErrorMessage(error, 'Loading Usage Data')
          setError(errorMsg)
          setLoading(false)
          setChartData(null) // Reset chartData on error
        }
      }
    }

    const fetchMailings = async () => {
      try {
        setMailingLoading(true)
        const response = await api.tenants.mailings.get({ tenantId })
        const fetchedEmails = response.data.emails || []
        if (isMounted) {
          setEmails(fetchedEmails)
          setMailingLoading(false)
        }
      } catch (error) {
        console.error('Error fetching mailing list:', error)
        if (isMounted) {
          const errorMsg = getErrorMessage(error, 'Loading Mailing List')
          setMailingError(errorMsg)
          setMailingLoading(false)
          setEmails([]) // Reset emails on error
        }
      }
    }

    if (tenantId) {
      fetchUsageData()
      fetchMailings()
    }

    return () => {
      isMounted = false // Cleanup to prevent state updates on unmounted components
    }
  }, [tenantId, theme.palette.secondary.main, theme.palette.secondary.light])

  const handleAddEmail = async (e) => {
    e.preventDefault()
    if (!newEmail) {
      setSnackbar({
        open: true,
        message: 'Email cannot be empty.',
        severity: 'warning',
      })
      return
    }

    //validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(newEmail)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid email.',
        severity: 'warning',
      })
      return
    }

    if (emails.includes(newEmail)) {
      setSnackbar({
        open: true,
        message: 'This email is already in the mailing list.',
        severity: 'warning',
      })
      return
    }

    try {
      await api.tenants.mailings.post({
        tenantId,
        emails: [newEmail],
      })
      setSnackbar({
        open: true,
        message: 'Email added successfully!',
        severity: 'success',
      })
      setNewEmail('')

      const response = await api.tenants.mailings.get({ tenantId })
      setEmails(response.data.emails || [])
    } catch (error) {
      console.error('Error adding email:', error)
      const errorMsg = getErrorMessage(error, 'Adding Email')
      setSnackbar({ open: true, message: errorMsg, severity: 'error' })
    }
  }

  const handleDeleteEmail = async (email) => {
    if (!window.confirm(`Are you sure you want to delete ${email}?`)) return

    try {
      await api.tenants.mailings.delete({
        tenantId,
        emails: [email],
      })
      setSnackbar({
        open: true,
        message: 'Email deleted successfully!',
        severity: 'success',
      })
      const response = await api.tenants.mailings.get({ tenantId })
      setEmails(response.data.emails || [])
    } catch (error) {
      console.error('Error deleting email:', error)
      const errorMsg = getErrorMessage(error, 'Deleting Email')
      setSnackbar({ open: true, message: errorMsg, severity: 'error' })
    }
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        // adjust the max value based on data
        // max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
        grid: {
          display: true,
          color: theme.palette.grey[300],
        },
      },
      x: {
        ticks: {
          maxTicksLimit: 31,
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => `${context.parsed.y}% token usage for this cycle`,
        },
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.primary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
      }}>
      <Typography
        variant="h4"
        textAlign="center"
        mb={4}
        sx={{ fontWeight: 'bold' }}>
        Dashboard
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, px: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                py: 3,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
                height: 500,
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
              }}>
              <Typography variant="h6" gutterBottom>
                Token Usage
              </Typography>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: 2, backgroundColor: 'grey.100' }}
                />
              ) : error ? (
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              ) : (
                <Box sx={{ flexGrow: 1 }}>
                  <Line options={chartOptions} data={chartData} />
                </Box>
              )}
            </Box>
          </Grid>
          {/* Uncomment and implement other sections as needed */}
          {/*
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                px: { xs: 2, sm: 4 },
                py: 3,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
                height: 500,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography variant="h6" gutterBottom>
                Trends and Insights
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <TrendsAndInsightsTable />
              </Box>
            </Box>
          </Grid>
          */}
        </Grid>

        {/* Mailing List Management */}
        <Box
          sx={{
            py: 3,
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            padding: 2,
          }}>
          <Typography variant="h6" gutterBottom>
            Organization Mailing List
          </Typography>

          {/* Add Email Form */}
          <Box
            component="form"
            onSubmit={handleAddEmail}
            sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
            <TextField
              label="Add Email"
              variant="outlined"
              size="small"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              fullWidth
            />
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </Box>

          {/* Mailing List Table */}
          {mailingLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              sx={{ borderRadius: 2, backgroundColor: 'grey.100' }}
            />
          ) : mailingError ? (
            <Typography color="error" variant="body1">
              {mailingError}
            </Typography>
          ) : (
            <MailingListTable emails={emails} onDelete={handleDeleteEmail} />
          )}
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ mt: 4 }}>
        <CopyrightFooter />
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default DashboardPage
