import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Box, Button, Drawer, Toolbar, Typography, IconButton } from '@mui/material'
import { Logout as LogoutIcon } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { clearUser } from 'reducers/user'
import { useWindowWidth } from 'hooks/useWindowWidth'

import { ReactComponent as ProposalIcon } from 'assets/proposal.svg'
import { ReactComponent as ProposalBuilderIcon } from 'assets/ProposalBuilder.svg'
import { ReactComponent as RfpSearchIcon } from 'assets/rpf-search.svg'
import { ReactComponent as HelpIcon } from 'assets/help.svg'
import { ReactComponent as SettingsIcon } from 'assets/settings.svg'
import { ReactComponent as DashboardIcon } from 'assets/dashboard.svg'
import appLogo from '../assets/logo.png'

const getIconForRoute = (routeName) => {
  switch (routeName) {
    case 'Dashboard':
      return <DashboardIcon style={{ color: 'inherit' }} />
    case 'My Proposals':
      return <ProposalIcon style={{ color: 'inherit' }} />
    case 'Proposal Builder':
      return <ProposalBuilderIcon style={{ color: 'inherit' }} />
    case 'RFP Search & Management':
      return <RfpSearchIcon style={{ color: 'inherit' }} />
    case 'Help & Support':
      return <HelpIcon style={{ color: 'inherit' }} />
    case 'Settings':
      return <SettingsIcon style={{ color: 'inherit' }} />
    default:
      return null
  }
}

const Sidenav = (props) => {
  const { routes, mobileDrawerOpen, setMobileDrawerOpen } = props
  const history = useHistory()
  const location = useLocation()
  const width = useWindowWidth()
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false)

  const handleLogout = () => {
    props.clearUser()
  }

  const handleSidebarCollapseToggle = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  let boxProps = {}

  if (width < 1200) {
    boxProps = {
      component: Drawer,
      open: mobileDrawerOpen,
      variant: 'temporary',
      onClose: () => setMobileDrawerOpen(false),
      sx: { flexGrow: 1 },
      PaperProps: {
        sx: {
          backgroundColor: 'background.sideNav',
          boxShadow: 'none',
          borderRight: 'none',
          width: isSidebarCollapsed ? '80px' : '300px',
        },
      },
      ModalProps: { keepMounted: true },
    }
  } else {
    boxProps = {
      component: Box,
      sx: {
        display: 'flex',
        flexDirection: 'column',
        width: isSidebarCollapsed ? '80px' : '300px', 
        maxWidth: '350px',
        height: '100vh',
        backgroundColor: 'background.sideNav',
        boxShadow: 'none',
        borderRight: 'none',
      },
    }
  }

  return (
    <Box {...boxProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1,
          height: '100%',
          overflowY: 'auto',
          boxShadow: 'none',
          borderRight: 'none',
          alignItems: 'center',
        }}
      >
        {width < 1200 && <Toolbar />}
        <IconButton
          color="inherit"
          onClick={handleSidebarCollapseToggle}
          sx={{ alignSelf: isSidebarCollapsed ? 'center' : 'flex-end', mb: 2 }}
        >
          {isSidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            mb: 2,
            flexDirection: isSidebarCollapsed ? 'column' : 'row', 
          }}
        >
          <img
            src={appLogo}
            alt="RFP Company Logo"
            style={{ width: '40px', height: '40px', marginRight: isSidebarCollapsed ? '0' : '8px' }}
          />
          {!isSidebarCollapsed && (
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 'bold', color: '#000' }}
            >
              FindMyRFP
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          {routes.map((route) => (
            <Button
              key={route.name}
              color="inherit"
              sx={{
                width: '100%',
                maxWidth: '240px',
                mb: 2,
                color: '#000',
                justifyContent: isSidebarCollapsed ? 'center' : 'flex-start', 
                ...(location.pathname === route.path
                  ? {
                      backgroundColor: 'secondary.dark',
                      color: '#FFF',
                    }
                  : {}),
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  color: '#FFF',
                },
              }}
              startIcon={getIconForRoute(route.name)}
              onClick={() => {
                history.push(route.path)
                setMobileDrawerOpen(false)
              }}
              variant="text"
            >
              {!isSidebarCollapsed && route.name} 
            </Button>
          ))}
        </Box>
        <Button
          sx={{
            width: '100%',
            maxWidth: isSidebarCollapsed ? '80px' : '200px', 
            mt: 'auto',
            gap: 1,
            justifyContent: isSidebarCollapsed ? 'center' : 'flex-start',
            color: 'secondary.dark',
          }}
          onClick={handleLogout}
          startIcon={<LogoutIcon fontSize="small" />}
        >
          {!isSidebarCollapsed && 'Logout'}
        </Button>
      </Box>
    </Box>
  )
}

const mapDispatchToProps = {
  clearUser,
}

export default connect(null, mapDispatchToProps)(Sidenav)
