import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TableCell,
  TextField,
  Collapse,
  Tooltip,
  Typography,
  TableContainer,
  Checkbox,
  ListItemText,
  Chip,
  Table,
  Link,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LinkIcon from '@mui/icons-material/Link'
import PersonIcon from '@mui/icons-material/Person'
import CopyrightFooter from 'components/CopyrightFooter'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import {
  Search,
  ManageSearch,
  CloudUpload,
  Clear,
  OpenInNew,
} from '@mui/icons-material'
import api from 'lib/api'
import styled from '@emotion/styled'
import moment from 'moment'
import EnhancedTable from 'components/DataTable'
import {
  setSamRows,
  setSbirRows,
  setClosedSbirRows,
  clearRfpData,
  setSelectedFile,
} from 'reducers/rfpSlice'
import { set } from 'lodash'
import { useSelector } from 'react-redux'

const CustomTableContainer = styled(TableContainer)({
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
})
const HeaderTableCell = styled(TableCell)({
  position: 'sticky',
  backgroundColor: '#E0E0E0',
  fontWeight: 'bold',
  verticalAlign: 'top',
  zIndex: 2,
})

const MultiLineTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  maxWidth: '500px',
  WebkitLineClamp: 3,
  whiteSpace: 'normal',
})

const ScrollableTableCell = styled(TableCell)({
  maxWidth: '200px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

const renderSimilarityBar = (similarity) => {
  const bars = []
  let color = 'red'
  let barCount = 1

  if (similarity > 45) {
    color = 'green'
    barCount = 3
  } else if (similarity >= 20) {
    color = 'yellow'
    barCount = 2
  }

  for (let i = 0; i < 3; i++) {
    bars.push(
      <Box
        key={i}
        sx={{
          width: '16px',
          height: '8px',
          backgroundColor: i < barCount ? color : '#e0e0e0',
          marginRight: 0.5,
          display: 'inline-block',
          borderRadius: '5px',
        }}
      />
    )
  }

  return <Box sx={{ cursor: 'pointer' }}>{bars}</Box>
}

const CompanyDataSelector = (props) => {
  const [loading, setLoading] = useState(true)
  const [selectFileOpen, setSelectFileOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const handleOpenSelectFile = () => setSelectFileOpen(true)
  const handleCloseSelectFile = () => setSelectFileOpen(false)
  const tenantId = useSelector((state) => state.user.tenantId)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackbar({ ...snackbar, open: false })
  }

  const fetchUploadedFiles = async () => {
    try {
      setLoading(true)

      const response = await api.tenants.rfps.get({ tenantId })
      if (response && response.data && response.data.rfps) {
        setUploadedFiles(response.data.rfps)
      } else {
        console.error('Unexpected API response format:', response)
      }
    } catch (e) {
      console.error(
        'Error fetching files:',
        e.message,
        e.response ? e.response.data : 'No response data'
      )
      setSnackbar({
        open: true,
        message: 'Failed to fetch files. Please try again.',
        severity: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchUploadedFiles()
  }, [props.companyData])

  return (
    <>
      <FormControl
        required
        fullWidth
        disabled={props.companyData && props.companyData.length > 0}>
        <InputLabel id="file-select-label">Select Company Data</InputLabel>
        <Select
          labelId="file-select-label"
          id="file-select"
          value={props.selectedFile}
          label="Select Company Data"
          onChange={props.handleSelectedFile}
          open={selectFileOpen}
          onOpen={handleOpenSelectFile}
          onClose={handleCloseSelectFile}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: 2,
                cursor: 'progress',
              }}>
              <CircularProgress />
            </Box>
          ) : (
            uploadedFiles.map((file, index) => (
              <MenuItem
                value={file.id}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}>
                  <Typography variant="body1">{file.name}</Typography>
                  <Typography fontSize={8}>{file.filename}</Typography>
                </Box>
                <Typography variant="caption">{file.description}</Typography>
              </MenuItem>
            ))
          )}
          <CompanyDataUpload
            key="NEW"
            value="NEW"
            title="Upload a new company document"
            onChange={(e) => props.setCompanyData(e.target.files)}
            fileValue={props.companyData}
            setSelectedFile={props.setSelectedFile}
            handleClose={handleCloseSelectFile}
            setIsUploading={props.setIsUploading}
            setIsUploadFormOpen={props.setIsUploadFormOpen}>
            {props.companyData &&
              props.companyData.length > 0 &&
              props.companyData[0].name}
          </CompanyDataUpload>
        </Select>
      </FormControl>
      {props.companyData && props.companyData.length > 0 && (
        <Tooltip
          title="Remove file"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -100],
                  },
                },
              ],
            },
          }}>
          <IconButton
            aria-label="clear-file"
            sx={{ width: 56 }}
            onClick={() => {
              props.setCompanyData([])
              props.setSelectedFile('')
              props.clear()
            }}>
            <Clear />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

const CompanyDataUpload = (props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 100,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  useEffect(() => {
    if (props.fileValue && props.fileValue.length > 0) {
      props.setSelectedFile('NEW')
      props.handleClose()
      props.setIsUploadFormOpen(true)
    }
  }, [
    props.fileValue,
    props.setSelectedFile,
    props.handleClose,
    props.setIsUploadFormOpen,
  ])

  return (
    <MenuItem
      value={props.value}
      component="label"
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        gap: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'background.paper',
        },
        '@media (hover: none)': {
          backgroundColor: 'primary.main',
          color: 'background.paper',
        },
      }}>
      <VisuallyHiddenInput
        type="file"
        onChange={props.onChange}
        multiple={props.multiple}
        accept=".pdf,.doc,.docx"
      />
      <Typography>{props.title}</Typography>
      <Box tabIndex={-1} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <CloudUpload />
      </Box>
    </MenuItem>
  )
}

const RFPSearchAndManagementPage = (props) => {
  const tenantId = useSelector((state) => state.user.tenantId)
  const [SAMView, setSAMView] = useState(false)
  const [sbirheadCells, setSbirHeadCells] = useState([
    {
      id: 'similarity',
      numeric: false,
      disablePadding: false,
      label: 'Similarity',
    },
    {
      id: 'solicitationTitle',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation title',
    },
    {
      id: 'solicitationNumber',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation number',
    },
    { id: 'program', numeric: false, disablePadding: false, label: 'Program' },
    { id: 'Phase', numeric: false, disablePadding: false, label: 'Phase' },
    { id: 'agency', numeric: false, disablePadding: false, label: 'Agency' },
    { id: 'branch', numeric: false, disablePadding: false, label: 'Branch' },
    {
      id: 'solicitation_year',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation year',
    },
    {
      id: 'release_date',
      numeric: false,
      disablePadding: false,
      label: 'Release Date',
    },
    {
      id: 'open_date',
      numeric: false,
      disablePadding: false,
      label: 'Open Date',
    },
    {
      id: 'close_date',
      numeric: true,
      disablePadding: false,
      label: 'Close Date',
    },
    {
      id: 'currentStatus',
      numeric: false,
      disablePadding: false,
      label: 'Current status',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'solicitationAgencyUrl',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation link',
    },
    {
      id: 'solicitation_agency_link',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation agency link',
    },
  ])
  const [samheadCells, setSamHeadCells] = useState([
    {
      id: 'similarity',
      numeric: false,
      disablePadding: false,
      label: 'Similarity',
    },
    {
      id: 'solicitationTitle',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation Title',
    },
    {
      id: 'solicitationNumber',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation number',
    },
    {
      id: 'fullParentPathName',
      numeric: false,
      disablePadding: false,
      label: 'Agency',
    },
    // {
    //   id: 'fullParentPathNameCode',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Agency code',
    // },
    {
      id: 'posted_date',
      numeric: false,
      disablePadding: false,
      label: 'Posted Date',
    },
    {
      id: 'archived_date',
      numeric: false,
      disablePadding: false,
      label: 'Archived Date',
    },
    {
      id: 'responseDeadline',
      numeric: false,
      disablePadding: false,
      label: 'Response Deadline',
    },
    {
      id: 'naicsCode',
      numeric: false,
      disablePadding: false,
      label: 'Naics Code',
    },
    {
      id: 'classification_code',
      numeric: false,
      disablePadding: false,
      label: 'Classification code',
    },
    { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
    {
      id: 'awar',
      numeric: false,
      disablePadding: false,
      label: 'Award',
    },
    // {
    //   id: 'description',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Description',
    // },
    // { id: 'uiLink', numeric: false, disablePadding: false, label: 'Ui link' },
    // {
    //   id: 'resourcesLink',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Resources link',
    // },
    {
      id: 'pointOfContact',
      numeric: false,
      disablePadding: false,
      label: 'Point Of contact',
    },
  ])
  const [openDescriptionSbir, setOpenDescriptionSbir] = useState(false)
  const [loadingSBIR, setLoadingSBIR] = useState(false)

  const [loadingSBIRWithDomain, setLoadingSBIRWithDomain] = useState(false)
  const [loadingSAM, setLoadingSAM] = useState(false)
  const {
    samRows,
    sbirRows,
    closedSbirRows,
    setSamRows,
    setSbirRows,
    setClosedSbirRows,
    clearRfpData,
  } = props

  // const [selectedFile, setSelectedFile] = useState('')
  const [companyData, setCompanyData] = useState([])
  const [fileName, setFileName] = useState('')
  const [fileDescription, setFileDescription] = useState('')
  const [open, setOpen] = useState(false)
  const [synopsisOpen, setSynopsisOpen] = useState(false)
  const [keywords, setKeywords] = useState([])
  const [searchName, setSearchName] = useState('')
  const [filters, setFilters] = useState([])
  const [typeOfSetAside, setTypeOfSetAside] = useState('')
  const [solicitationType, setSolicitationType] = useState([])
  const [isSolicitationTypeOpen, setIsSolicitationTypeOpen] = useState(false)
  const solicitationTypesOptions = ['SBIR', 'STTR', 'BAA', 'All others']
  const [selectedFilter, setSelectedFilter] = useState('')
  const [domains, setDomains] = useState([])
  const [isNaicsOpen, setIsNaicsOpen] = useState(false)
  const [isAgencyOpen, setIsAgencyOpen] = useState(false)
  const [isDateOpen, setIsDateOpen] = useState(false)
  const [isTypeOfSetAsideOpen, setIsTypeOfSetAsideOpen] = useState(false)
  const [isDomainSpecificOpen, setIsDomainSpecificOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(false)
  const [naicsCode, setNaicsCode] = useState('')
  const [agency, setAgency] = useState('')
  const [isClosed, setIsClosed] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const rate = true
  const [withDomains, setWithDomains] = useState(false)
  const [dateFrom, setDateFrom] = useState(
    moment().startOf('year').format('YYYY-MM-DD')
  )

  const [dateTo, setDateTo] = useState(
    moment().endOf('year').format('YYYY-MM-DD')
  )

  const [samTotalCount, setSamTotalCount] = useState(0)
  const [sbirTotalCount, setSbirTotalCount] = useState(0)
  const [closedSbirTotalCount, setClosedSbirTotalCount] = useState(0)

  const [samPage, setSamPage] = useState(0)
  const [samRowsPerPage, setSamRowsPerPage] = useState(10)
  const [isLastPage, setIsLastPage] = useState(false)

  const [sbirPage, setSbirPage] = useState(0)
  const [sbirRowsPerPage, setSbirRowsPerPage] = useState(10)

  const [closedSbirPage, setClosedSbirPage] = useState(0)
  const [closedSbirRowsPerPage, setClosedSbirRowsPerPage] = useState(10)

  const getCurrentPosts = (rows, page, rowsPerPage) => {
    const firstIndex = page * rowsPerPage
    const lastIndex = firstIndex + rowsPerPage
    return rows.slice(firstIndex, lastIndex)
  }

  const [selectedSolicitation, setSelectedSolicitation] = useState({
    title: '',
    description: '',
  })
  const [isSolicitationModalOpen, setIsSolicitationModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const parseResourceLinks = (resource_links) => {
    if (!resource_links) return []
    try {
      const parsed = JSON.parse(resource_links)
      return Array.isArray(parsed) ? parsed.filter(Boolean) : []
    } catch (e) {
      console.error('Error parsing resource_links:', e)
      return []
    }
  }
  const filterSolicitations = (data) => {
    if (!data || data.length === 0) return data
    if (!searchQuery) return data
    const filtered = data.filter((row) => {
      const query = searchQuery.toLowerCase()

      const title = row.title || row.solicitationTitle || ''
      const solicitationNumber = row.solicitationNumber || ''
      const agency = row.agency || row.fullParentPathName || ''
      const description = row.description || ''
      const program = row.program || ''
      const phase = row.phase || ''
      const currentStatus = row.currentStatus || row.active || ''

      const matches =
        title.toLowerCase().includes(query) ||
        solicitationNumber.toLowerCase().includes(query) ||
        agency.toLowerCase().includes(query) ||
        description.toLowerCase().includes(query) ||
        program.toLowerCase().includes(query) ||
        phase.toLowerCase().includes(query) ||
        currentStatus.toLowerCase().includes(query)

      return matches
    })

    console.log(`Filtering with query "${searchQuery}":`, filtered)
    return filtered
  }

  const filteredSamRows = useMemo(() => {
    return filterSolicitations(samRows)
  }, [samRows, searchQuery])

  const filteredSbirRows = useMemo(() => {
    return filterSolicitations(sbirRows)
  }, [sbirRows, searchQuery])

  const filteredClosedSbirRows = useMemo(() => {
    return filterSolicitations(closedSbirRows)
  }, [closedSbirRows, searchQuery])

  const samCurrentPageData = useMemo(() => {
    return getCurrentPosts(filteredSamRows, samPage, samRowsPerPage)
  }, [filteredSamRows, samPage, samRowsPerPage])

  const sbirCurrentPageData = useMemo(() => {
    return getCurrentPosts(filteredSbirRows, sbirPage, sbirRowsPerPage)
  }, [filteredSbirRows, sbirPage, sbirRowsPerPage])

  const closedSbirCurrentPageData = useMemo(() => {
    return getCurrentPosts(
      filteredClosedSbirRows,
      closedSbirPage,
      closedSbirRowsPerPage
    )
  }, [filteredClosedSbirRows, closedSbirPage, closedSbirRowsPerPage])

  const [pointOfContact, setPointOfContact] = useState(null)
  const [isPocModalOpen, setIsPocModalOpen] = useState(false)
  const [awardDetails, setAwardDetails] = useState(null)
  const [isAwardModalOpen, setIsAwardModalOpen] = useState(false)
  const [resourceLinks, setResourceLinks] = useState([])
  const [isResourceLinksModalOpen, setIsResourceLinksModalOpen] =
    useState(false)

  const handleOpenAwardModal = (event, award) => {
    event.preventDefault()
    event.stopPropagation()
    setAwardDetails(award)
    setIsAwardModalOpen(true)
  }

  const handleCloseAwardModal = () => {
    setIsAwardModalOpen(false)
    setAwardDetails(null)
  }

  const handleOpenResourceLinksModal = (event, links) => {
    event.preventDefault()
    event.stopPropagation()
    setResourceLinks(links)
    setIsResourceLinksModalOpen(true)
  }

  const handleCloseResourceLinksModal = () => {
    setIsResourceLinksModalOpen(false)
    setResourceLinks([])
  }

  const handleDelete = (value) => {
    setSolicitationType((prev) => prev.filter((item) => item !== value))
  }

  const handleOpenPocModal = (event, contact) => {
    event.preventDefault()
    event.stopPropagation()
    setPointOfContact(contact)
    setIsPocModalOpen(true)
  }

  const handleClosePocModal = () => {
    setIsPocModalOpen(false)
    setPointOfContact(null)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setIsUploadFormOpen(false)
    setIsUploading(false)

    setCompanyData([])
    setFileName('')
    setFileDescription('')
    setDomains([])

    // Only reset selectedFile if cancelling an upload
    if (isUploadFormOpen || isUploading) {
      props.setSelectedFile('')
      setKeywords([])
    }
  }
  const [errorMessage, setErrorMessage] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadFormOpen, setIsUploadFormOpen] = useState(false)
  const handleSelectedFile = async (event) => {
    const selectedValue = event.target.value
    props.setSelectedFile(selectedValue)

    if (selectedValue === 'NEW') {
      setIsUploadFormOpen(true)
    } else {
      setIsUploading(false)
      try {
        const response = await api.tenants.rfps.get({ tenantId })

        const doms = response.data.rfps.find((element) => {
          return element.id === selectedValue
        })
        setDomains(doms.domains)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity,
    })
  }
  const getErrorMessage = (error, context = '') => {
    if (!error) return 'An unexpected error occurred.'
    if (error.response) {
      const { status, data } = error.response
      if (status === 400) {
        if (data && data.message) {
          let errorMsg = data.message
          if (
            data.fields &&
            Array.isArray(data.fields) &&
            data.fields.length > 0
          ) {
            errorMsg += ` Missing fields: ${data.fields.join(', ')}.`
          }
          return context ? `${context}: ${errorMsg}` : errorMsg
        }
        return context ? `${context}: Bad request.` : 'Bad request.'
      }

      if (status === 404) {
        return context
          ? `${context}: Resource not found.`
          : 'Resource not found.'
      }

      if (status >= 500) {
        return context
          ? `${context}: Server error. Please try again later.`
          : 'Server error. Please try again later.'
      }
      return data?.message
        ? context
          ? `${context}: ${data.message}`
          : data.message
        : context
        ? `${context}: An error occurred during the operation.`
        : 'An error occurred during the operation.'
    }
    if (error.message && error.message.includes('Network Error')) {
      return context
        ? `${context}: Unable to connect to the server. Please check your internet connection.`
        : 'Unable to connect to the server. Please check your internet connection.'
    }
    return context
      ? `${context}: ${error.message || 'An unexpected error occurred.'}`
      : error.message || 'An unexpected error occurred.'
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackbar({ ...snackbar, open: false })
  }

  // const handleSamRowClick = (row) => {
  //   if (row.uiLink) {
  //     window.open(row.uiLink, '_blank');
  //   }
  // };
  const handleSamRowClick = (row) => {
    setSelectedSolicitation({
      title: row.title || '',
      description: row.description || '',
    })
    setIsSolicitationModalOpen(true)
  }
  const handleCloseSolicitationModal = () => setIsSolicitationModalOpen(false)

  const rowSamCells = (row) => (
    console.log('Rendering SAM Row:', row),
    (
      <>
        <TableCell align="left">
          {renderSimilarityBar(row.similarity)}
        </TableCell>
        <ScrollableTableCell align="left">
          <Tooltip title={row.title} placement="top">
            <MultiLineTypography variant="body2">
              {row.title}
            </MultiLineTypography>
          </Tooltip>
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          {row.solicitationNumber}
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          <Tooltip title={row.agency} placement="top">
            <MultiLineTypography variant="body2">
              {row.agency}
            </MultiLineTypography>
          </Tooltip>
        </ScrollableTableCell>
        {/* <TableCell align="left">{row.agencyCode}</TableCell> */}
        <TableCell align="left">{row.postedDate}</TableCell>
        <TableCell align="left">{row.archiveDate}</TableCell>
        <TableCell align="left">{row.responseDeadline}</TableCell>
        <TableCell align="left">
          <Link
            href={`https://www.naics.com/naics-code-description/?code=${row.naicsCode}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            color="primary">
            {row.naicsCode}
          </Link>
        </TableCell>

        <TableCell align="left">{row.classificationCode}</TableCell>
        <TableCell align="left">{row.active}</TableCell>
        <ScrollableTableCell align="left">
          <IconButton
            color="primary"
            onClick={(event) => handleOpenAwardModal(event, row.awardDetails)}
            aria-label="view award details">
            <EmojiEventsIcon />
          </IconButton>
        </ScrollableTableCell>

        {/* <ScrollableTableCell align="left">
          <Tooltip title={row.description} placement="top">
            <MultiLineTypography variant="body2">
              {row.description}
            </MultiLineTypography>
          </Tooltip>
        </ScrollableTableCell> */}
        {/* <ScrollableTableCell align="left">
          <Tooltip title="View UI Link">
            <IconButton
              href={row.uiLink}
              target="_blank"
              rel="noreferrer"
              sx={{ color: 'blue' }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </ScrollableTableCell> */}
        {/* <ScrollableTableCell align="left">
          <IconButton
            color="primary"
            onClick={(event) => handleOpenResourceLinksModal(event, row.resourceLinks)}
            aria-label="view resource links">
            <LinkIcon />
          </IconButton>
        </ScrollableTableCell> */}
        <ScrollableTableCell align="left">
          <IconButton
            color="primary"
            onClick={(event) => handleOpenPocModal(event, row.pointOfContact)}
            aria-label="view POC details">
            <PersonIcon />
          </IconButton>
        </ScrollableTableCell>
      </>
    )
  )

  const createSamData = (row) => {
    let awardDetails = null

    if (row.award) {
      try {
        awardDetails = JSON.parse(row.award)
        if (awardDetails.date) {
          awardDetails.date = moment(awardDetails.date).format('MM/DD/YYYY')
        } else {
          awardDetails.date = '-'
        }
        awardDetails.amount = awardDetails.amount
          ? parseFloat(awardDetails.amount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          : '-'
        awardDetails.number = awardDetails.number || '-'
        if (awardDetails.awardee && awardDetails.awardee.location) {
          const loc = awardDetails.awardee.location
          awardDetails.awardee.location = {
            zip: loc.zip || '-',
            city: loc.city?.name || '-',
            state: loc.state?.name || '-',
            country: loc.country?.name || '-',
          }
        } else {
          awardDetails.awardee = {
            name: awardDetails.awardee?.name || '-',
            location: {
              zip: '-',
              city: '-',
              state: '-',
              country: '-',
            },
          }
        }
      } catch (error) {
        console.error('Error parsing award data:', error)
        awardDetails = {
          date: '-',
          amount: '-',
          number: '-',
          awardee: {
            name: '-',
            location: {
              zip: '-',
              city: '-',
              state: '-',
              country: '-',
            },
          },
        }
      }
    } else {
      awardDetails = {
        date: '-',
        amount: '-',
        number: '-',
        awardee: {
          name: '-',
          location: {
            zip: '-',
            city: '-',
            state: '-',
            country: '-',
          },
        },
      }
    }

    let parsedPOC = []
    try {
      const pocData = JSON.parse(row.point_of_contact)
      if (Array.isArray(pocData)) {
        parsedPOC = pocData
      } else if (typeof pocData === 'object' && pocData !== null) {
        parsedPOC = [pocData]
      }
    } catch (error) {
      console.error('Error parsing point_of_contact:', error)
    }
    const data = {
      similarity: (row.similarity * 100).toFixed(2),
      title: row.title,
      solicitationNumber: row.solicitation_number,
      agency: row.full_parent_path_name,
      // agencyCode: row.full_parent_path_code,
      naicsCode: row.naics_code,
      classificationCode: row.classification_code,
      active: row.active,
      description: row.description,
      uiLink: row.ui_link,
      // resourceLinks: parseResourceLinks(row.resource_links),
      pointOfContact: parsedPOC,
      awardDetails,
      postedDate: row.posted_date
        ? moment(row.posted_date).format('MM/DD/YYYY')
        : 'N/A',
      archiveDate: row.archive_date
        ? moment(row.archive_date).format('MM/DD/YYYY')
        : 'N/A',
      responseDeadline: row.response_deadline
        ? moment(row.response_deadline).format('MM/DD/YYYY')
        : 'N/A',
    }
    return data
  }

  const createSbirData = (row) => {
    const data = {
      similarity: (row.similarity * 100).toFixed(2),
      solicitationTitle: row.solicitation_title,
      solicitationNumber: row.solicitation_number,
      agency: row.agency,
      branch: row.branch,
      solicitationYear: row.solicitation_year,
      phase: row.phase,
      program: row.program,
      currentStatus: row.current_status,
      description: row.description,
      sbirSolicitationLink: row.sbir_solicitation_link,
      solicitationAgencyUrl: row.solicitation_agency_url,
      release_date: row.release_date
        ? moment(row.release_date).format('MM/DD/YYYY')
        : 'N/A',
      open_date: row.open_date
        ? moment(row.open_date).format('MM/DD/YYYY')
        : 'N/A',
      close_date: row.close_date
        ? moment(row.close_date).format('MM/DD/YYYY')
        : 'N/A',
      rating: row.rating,
    }
    return data
  }

  const handleUpload = async () => {
    if (companyData.length === 0) {
      showSnackbar('Please select a file to upload.', 'warning')
      return
    }

    const file = companyData[0]
    const validExtensions = ['pdf', 'docx']
    const fileExtension = file.name.split('.').pop().toLowerCase()

    if (!validExtensions.includes(fileExtension)) {
      showSnackbar(
        'Invalid file type. Only .pdf and .docx files are allowed.',
        'warning'
      )
      return
    }

    if (fileName.trim() === '' || fileDescription.trim() === '') {
      showSnackbar('Please provide both file name and description.', 'warning')
      return
    }

    setIsUploading(true)
    try {
      showSnackbar('Uploading document...', 'info')

      const formData = new FormData()
      formData.append('document', file)
      formData.append('name', fileName)
      formData.append('description', fileDescription)

      const response = await api.rfp.files({ tenantId, formData })

      setDomains(file.domains)
      const uploadedFileId = response.data.id
      props.setSelectedFile(uploadedFileId)
      setCompanyData([])
      setFileName('')
      setFileDescription('')

      if (response.data.domains) {
        setDomains(response.data.domains)
      }

      showSnackbar('Document uploaded successfully!', 'success')
      setIsUploadFormOpen(false)
      return response.data
    } catch (e) {
      console.error(e)
      let errorMsg = 'Error uploading document. Please try again.'
      if (e.response && e.response.data && e.response.data.message) {
        errorMsg = `Error: ${e.response.data.message}`
      }
      showSnackbar(errorMsg, 'error')
    } finally {
      setIsUploading(false)
    }
  }

  const handleSearchSbirSam = async (page, rowsPerPage) => {
    const required = true

    const solicitationTypeFilter = solicitationType.join(',')

    setLoadingSBIR(true)
    let newRfpId = null
    try {
      if (props.selectedFile === 'NEW') {
        showSnackbar('Uploading new company document...', 'info')
        newRfpId = await handleUpload()
        if (!newRfpId) {
          throw new Error('Document upload failed.')
        }
      }
      const response = await api.rfp.sam({
        rate,
        dateFrom,
        dateTo,
        keywords,
        tenantId,
        rfpId: newRfpId || props.selectedFile,
        solicitationType: solicitationTypeFilter,
        agency,
        naicsCode,
        typeOfSetAside,
        closed: isClosed ? 1 : 0,
        required,
        perPage: rowsPerPage,
        page: page + 1,
        withDomains,
      })

      const samData = response.data.results.map((row) => createSamData(row))
      setSamRows(samData)

      // Determine if this is the last page
      if (samData.length < rowsPerPage) {
        setIsLastPage(true)
      } else {
        setIsLastPage(false)
      }
      showSnackbar('Search completed successfully!', 'success')
    } catch (error) {
      console.error('Error during RFP search:', error)

      let errorMsg = 'Search failed. Please check your inputs and try again.'
      if (error.message === 'Document upload failed.') {
        errorMsg = 'Failed to upload company document.'
      } else {
        errorMsg = getErrorMessage(error, 'RFP Search')
      }
      showSnackbar(errorMsg, 'error')
    } finally {
      setLoadingSBIR(false)
    }
  }

  const handleSearchSbirSamWithDomain = async (page, rowsPerPage) => {
    const required = true

    setLoadingSBIRWithDomain(true)
    let newRfpId = null

    try {
      if (props.selectedFile === 'NEW') {
        showSnackbar('Uploading new company document...', 'info')
        newRfpId = await handleUpload()
        if (!newRfpId) {
          throw new Error('Document upload failed.')
        }
      }
      showSnackbar('Searching for SAM solicitations with domains...', 'info')

      const response = await api.rfp.sam({
        rate,
        dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
        dateTo: moment().endOf('year').format('YYYY-MM-DD'),
        keywords,
        tenantId,
        rfpId: newRfpId || props.selectedFile,
        perPage: rowsPerPage,
        page: page + 1,
        agency,
        naicsCode,
        typeOfSetAside,
        closed: isClosed ? 1 : 0,
        required,
        withDomains: true,
      })

      if (response && response.data && Array.isArray(response.data.results)) {
        const samData = response.data.results.map((row) => createSamData(row))
        setSamRows(samData)

        // Determine if this is the last page
        if (samData.length < rowsPerPage) {
          setIsLastPage(true)
        } else {
          setIsLastPage(false)
        }

        showSnackbar('Search with domains completed successfully!', 'success')
      } else {
        showSnackbar(
          'Unexpected response format. Please try again later.',
          'warning'
        )
        console.error('Unexpected response format:', response)
      }
    } catch (error) {
      console.error('Error during search with domains:', error)
      const errorMsg = getErrorMessage(error, 'Search with Domains')
      showSnackbar(errorMsg, 'error')
    } finally {
      setLoadingSBIRWithDomain(false)
    }
  }

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await api.rfp.getFilters({ tenantId })

        if (response.data && Array.isArray(response.data.type_of_set_aside)) {
          setFilters(response.data.type_of_set_aside)
        } else {
          console.error('Expected filters in response, got:', response.data)
          showSnackbar(
            'Unexpected response format. Filters could not be loaded.',
            'warning'
          )

          setFilters([])
        }
      } catch (e) {
        console.error('Error fetching filters:', e)
        const errorMsg = getErrorMessage(
          e,
          'Failed to fetch filters. Please try again.'
        )
        showSnackbar(errorMsg, 'error')

        setFilters([])
      }
    }

    fetchFilters()
  }, [tenantId])

  // Handle page change
  const handleSamPageChange = (event, newPage) => {
    setSamPage(newPage)
    handleSearchSbirSam(newPage, samRowsPerPage)
  }

  // Handle rows per page change
  const handleSamRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setSamRowsPerPage(newRowsPerPage)
    setSamPage(0)
    handleSearchSbirSam(0, newRowsPerPage)
  }
  const handleSbirPageChange = (event, newPage) => {
    setSbirPage(newPage)
  }

  const handleSbirRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setSbirRowsPerPage(newRowsPerPage)
    setSbirPage(0)
  }

  const handleClosedSbirPageChange = (event, newPage) => {
    setClosedSbirPage(newPage)
  }

  const handleClosedSbirRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setClosedSbirRowsPerPage(newRowsPerPage)
    setClosedSbirPage(0)
  }

  const handleSaveSearch = async () => {
    try {
      if (!props.selectedFile || props.selectedFile === '') {
        throw new Error('A company document must be selected to save search.')
      }
      showSnackbar('Saving your search...', 'info')

      const response = await api.tenants.newsletters.post({
        rfpId: props.selectedFile,
        withDomains,
        agency,
        naicsCode,
        typeOfSetAside,
        tenantId,
        keywords,
        name: searchName,
        required: true,
      })

      if (response) {
        showSnackbar('Search saved to newsletter successfully!', 'success')
      }
    } catch (error) {
      console.error('Error saving search:', error)

      console.error('Error saving search:', error)
      const errorMsg = getErrorMessage(error, 'Search Save')
      showSnackbar(errorMsg, 'error')
    }
  }

  const [searches, setSearches] = useState(() => {
    const savedSearches = localStorage.getItem('userSearchHistory')
    return savedSearches ? JSON.parse(savedSearches) : []
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'relative',
          mb: 2,
        }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 2,
          }}>
          RFP Search & Management
        </Typography>

        <Box sx={{ width: 'auto', height: '36px', visibility: 'hidden' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              gap: 1,
              flexGrow: 1,
              textWrap: 'nowrap',
              color: '#FFF',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleOpen}>
            <ManageSearch fontSize="medium" />
            <Typography
              sx={{
                fontSize: { xs: 12, sm: 16 },
              }}>
              Search for RFP
            </Typography>
          </Button>
          <TextField
            sx={{ flexGrow: 2 }}
            id="rfp-search"
            label="Filter results"
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              sx: { backgroundColor: 'background.paper' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle search"
                    // onClick={handleFilter}
                    onMouseDown={(e) => e.preventDefault()}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <CustomTableContainer>
          <Table>
            <EnhancedTable
              title="SAM solicitations"
              rows={filteredSamRows}
              onRowClick={handleSamRowClick}
              rowCells={rowSamCells}
              headCells={samheadCells}
              page={samPage}
              rowsPerPage={samRowsPerPage}
              onPageChange={handleSamPageChange}
              onRowsPerPageChange={handleSamRowsPerPageChange}
              isLastPage={isLastPage}
              filterDisabled
              selectionDisabled
            />
          </Table>
        </CustomTableContainer>
        {/* <CustomTableContainer>
          <Table>
            <EnhancedTable
              title="Sbir solicitations"
              rows={
                sbirCurrentPageData && sbirCurrentPageData.length > 0
                  ? sbirCurrentPageData
                  : []
              }
              rowCells={rowCells}
              headCells={sbirheadCells}
              filterDisabled
              selectionDisabled
              onRowClick={handleRowClick}
              page={sbirPage}
              rowsPerPage={sbirRowsPerPage}
              totalCount={sbirRows.length}
              onPageChange={(event, newPage) => {
                setSbirPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10)
                setSbirRowsPerPage(newRowsPerPage)
                setSbirPage(0)
              }}
            />
          </Table>
        </CustomTableContainer> */}
        {/* <CustomTableContainer>
          <Table>
            <EnhancedTable
              title="Sbir past/closed solicitations"
              rows={
                closedSbirCurrentPageData &&
                closedSbirCurrentPageData.length > 0
                  ? closedSbirCurrentPageData
                  : []
              }
              rowCells={rowCells}
              headCells={sbirheadCells}
              filterDisabled
              selectionDisabled
              onRowClick={handlePastClosedRowClick}
              page={closedSbirPage}
              rowsPerPage={closedSbirRowsPerPage}
              totalCount={closedSbirRows.length}
              onPageChange={(event, newPage) => {
                setClosedSbirPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10)
                setClosedSbirRowsPerPage(newRowsPerPage)
                setClosedSbirPage(0)
              }}
            />
          </Table>
        </CustomTableContainer> */}
      </Box>
      <Modal open={open} onClose={null}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            borderRadius: 4,
            width: { xs: '90%', sm: 400, md: 500 },
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5">Search for RFP</Typography>

                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: { xs: 5, sm: 10 },
                    top: { xs: 5, sm: 10 },
                  }}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                }}>
                {!isUploadFormOpen && !isUploading && (
                  <TextField
                    required
                    label="Enter search name"
                    variant="outlined"
                    size="small"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    sx={{
                      flexGrow: 0,
                      width: { xs: '100%', sm: 'auto' },
                    }}
                  />
                )}
                {!isUploadFormOpen && !isUploading && (
                  <Button
                    variant="contained"
                    onClick={handleSaveSearch}
                    disabled={!searchName.trim()}
                    sx={{
                      whiteSpace: 'nowrap',
                    }}>
                    Save Search to Newsletter
                  </Button>
                )}
              </Grid>
            </Grid>
            {!isUploadFormOpen && !isUploading && (
              <>
                <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                  <CompanyDataSelector
                    companyData={companyData}
                    selectedFile={props.selectedFile}
                    setCompanyData={setCompanyData}
                    setSelectedFile={props.setSelectedFile}
                    handleSelectedFile={handleSelectedFile}
                    setIsUploading={setIsUploading}
                    setIsUploadFormOpen={setIsUploadFormOpen}
                    clear={() => {
                      setFileName('')
                      setFileDescription('')
                      setDomains([])
                    }}
                  />
                </Grid>
                {domains.length !== 0 && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="search"
                      label="Domains"
                      value={domains.map((dom) => `• ${dom.domain}`).join('\n')}
                      variant="outlined"
                      disabled={true}
                      multiline
                      rows={3}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="search"
                    label="Search keywords"
                    type="search"
                    variant="outlined"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Solicitation Type</Typography>
                    <IconButton
                      onClick={() =>
                        setIsSolicitationTypeOpen(!isSolicitationTypeOpen)
                      }>
                      {isSolicitationTypeOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isSolicitationTypeOpen}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="solicitation-type-label">
                        Select Solicitation Type
                      </InputLabel>
                      <Select
                        labelId="solicitation-type-label"
                        id="solicitation-type"
                        multiple
                        value={solicitationType}
                        onChange={(e) => setSolicitationType(e.target.value)}
                        label="Select Solicitation Type"
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => handleDelete(value)}
                                sx={{ borderRadius: '16px' }}
                              />
                            ))}
                          </Box>
                        )}>
                        <MenuItem value="">
                          <em>Select Solicitation Type</em>
                        </MenuItem>
                        {solicitationTypesOptions.map((type) => (
                          <MenuItem key={type} value={type}>
                            <Checkbox
                              checked={solicitationType.indexOf(type) > -1}
                            />
                            <ListItemText primary={type} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Collapse>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Agency</Typography>
                    <IconButton onClick={() => setIsAgencyOpen(!isAgencyOpen)}>
                      {isAgencyOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isAgencyOpen}>
                    <TextField
                      fullWidth
                      id="agency"
                      label="Agency"
                      variant="outlined"
                      value={agency}
                      onChange={(e) => setAgency(e.target.value)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Date</Typography>
                    <IconButton onClick={() => setIsDateOpen(!isDateOpen)}>
                      {isDateOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isDateOpen}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="date"
                          label="Start Date"
                          variant="outlined"
                          value={dateFrom}
                          onChange={(e) => setDateFrom(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="date"
                          label="End Date"
                          variant="outlined"
                          value={dateTo}
                          onChange={(e) => setDateTo(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Naics Code</Typography>
                    <IconButton onClick={() => setIsNaicsOpen(!isNaicsOpen)}>
                      {isNaicsOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isNaicsOpen}>
                    <TextField
                      fullWidth
                      id="naics-code"
                      label="Naics Code"
                      variant="outlined"
                      value={naicsCode}
                      onChange={(e) => setNaicsCode(e.target.value)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Type of Set Aside</Typography>
                    <IconButton
                      onClick={() =>
                        setIsTypeOfSetAsideOpen(!isTypeOfSetAsideOpen)
                      }>
                      {isTypeOfSetAsideOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isTypeOfSetAsideOpen}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="type-of-set-aside-label">
                        Select Type Of Set Aside
                      </InputLabel>
                      <Select
                        labelId="type-of-set-aside-label"
                        id="type-of-set-aside"
                        value={typeOfSetAside}
                        label="Select Type Of Set Aside"
                        onChange={(e) => setTypeOfSetAside(e.target.value)}
                        displayEmpty>
                        <MenuItem value="">Select Type Of Set Aside</MenuItem>
                        {filters.map((filter, index) => (
                          <MenuItem key={index} value={filter}>
                            {filter}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Collapse>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Closed Solicitations</Typography>
                    <IconButton onClick={() => setIsClosed(!isClosed)}>
                      {isClosed ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isClosed}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckboxChecked}
                            onChange={(e) => {
                              const checked = e.target.checked
                              setIsCheckboxChecked(checked)
                              setIsClosed(checked)
                            }}
                            name="closed-solicitations"
                            color="primary"
                          />
                        }
                        label="Include Closed Solicitations"
                      />
                    </FormControl>
                  </Collapse>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      textAlign: 'center',
                      marginTop: 4,
                    }}>
                    <Button
                      disabled={
                        props.selectedFile === '' ||
                        (props.selectedFile === 'NEW' &&
                          (fileName === '' || fileDescription === '')) ||
                        loadingSBIR
                      }
                      onClick={() => handleSearchSbirSam(0, samRowsPerPage)}
                      variant="contained"
                      sx={{
                        width: '100%',
                        maxWidth: '400px',
                      }}>
                      {loadingSBIR && (
                        <CircularProgress
                          size={20}
                          sx={{ color: 'grey', mr: 1 }}
                        />
                      )}
                      Search
                    </Button>
                    <Button
                      disabled={
                        props.selectedFile === '' ||
                        (props.selectedFile === 'NEW' &&
                          (fileName === '' || fileDescription === '')) ||
                        loadingSBIRWithDomain
                      }
                      onClick={() =>
                        handleSearchSbirSamWithDomain(0, sbirRowsPerPage)
                      }
                      variant="contained"
                      sx={{
                        width: '100%',
                        maxWidth: '400px',
                      }}>
                      {loadingSBIRWithDomain && (
                        <CircularProgress
                          size={20}
                          sx={{ color: 'grey', mr: 1 }}
                        />
                      )}
                      Search using company document
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
            {isUploadFormOpen && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Upload New Document</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="file-name"
                    label="Document Name"
                    variant="outlined"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} mb={4}>
                  <TextField
                    required
                    fullWidth
                    id="file-description"
                    label="Document Description"
                    variant="outlined"
                    value={fileDescription}
                    onChange={(e) => setFileDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleUpload}
                    disabled={
                      companyData.length === 0 ||
                      fileName.trim() === '' ||
                      fileDescription.trim() === '' ||
                      isUploading
                    }
                    startIcon={isUploading && <CircularProgress size={20} />}>
                    {isUploading ? 'Uploading...' : 'Upload Document'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      setIsUploading(false)
                      props.setSelectedFile('')
                      setCompanyData([])
                      setFileName('')
                      setFileDescription('')
                      setDomains([])
                    }}>
                    Cancel Upload
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>
      <Modal open={isPocModalOpen} onClose={handleClosePocModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            width: { xs: 350, md: 500 },
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',

            /* Custom Scrollbar Styles */
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1',
          }}>
          <Typography variant="h6" gutterBottom>
            Point of Contact
          </Typography>
          {pointOfContact && pointOfContact.length > 0 ? (
            pointOfContact.map((contact, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  borderBottom: '1px solid #e0e0e0',
                  pb: 2,
                }}>
                <Typography variant="subtitle1">
                  <strong>Full Name:</strong> {contact.fullName || '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {contact.email || '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Phone:</strong> {contact.phone || '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Fax:</strong> {contact.fax || '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Type:</strong> {contact.type || '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Title:</strong> {contact.title || '-'}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body1">
              No Point of Contact information available.
            </Typography>
          )}
        </Box>
      </Modal>
      <Modal open={isAwardModalOpen} onClose={handleCloseAwardModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            width: { xs: 350, md: 500 },
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1',
          }}>
          <Typography variant="h6" gutterBottom>
            Award Details
          </Typography>
          {awardDetails ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <strong>Date:</strong> {awardDetails.date || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <strong>Amount:</strong> {awardDetails.amount || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <strong>Number:</strong> {awardDetails.number || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <strong>Awardee Name:</strong>{' '}
                  {awardDetails.awardee.name || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <strong>Location:</strong>
                </Typography>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  <strong>City:</strong>{' '}
                  {awardDetails.awardee.location.city || '-'}
                </Typography>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  <strong>State:</strong>{' '}
                  {awardDetails.awardee.location.state || '-'}
                </Typography>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  <strong>Zip:</strong>{' '}
                  {awardDetails.awardee.location.zip || '-'}
                </Typography>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  <strong>Country:</strong>{' '}
                  {awardDetails.awardee.location.country || '-'}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">
              No Award information available.
            </Typography>
          )}
        </Box>
      </Modal>
      <Modal
        open={isResourceLinksModalOpen}
        onClose={handleCloseResourceLinksModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: { xs: 3, sm: 4 },
            borderRadius: 4,
            width: { xs: '90%', sm: '70%', md: '60%' },
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1',
          }}>
          <Typography variant="h6" gutterBottom>
            Resource Links
          </Typography>
          {resourceLinks && resourceLinks.length > 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {resourceLinks.map((link, index) => (
                <Link
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body1"
                  sx={{
                    wordBreak: 'break-all',
                    color: 'primary.main',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}>
                  {link}
                </Link>
              ))}
            </Box>
          ) : (
            <Typography variant="body1">
              No Resource Links available.
            </Typography>
          )}
        </Box>
      </Modal>
      <Modal
        open={isSolicitationModalOpen}
        onClose={handleCloseSolicitationModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            width: { xs: '90%', sm: '70%', md: '60%' },
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1',
          }}>
          <Typography variant="h6" gutterBottom>
            {selectedSolicitation.title}
          </Typography>
          <Typography variant="body1">
            {selectedSolicitation.description}
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box sx={{ flexGrow: 1 }} />
      <CopyrightFooter />
    </Box>
  )
}
const mapStateToProps = (state) => ({
  samRows: state.rfp.samRows,
  sbirRows: state.rfp.sbirRows,
  closedSbirRows: state.rfp.closedSbirRows,
  selectedFile: state.rfp.selectedFile,
})
const mapDispatchToProps = {
  setSamRows,
  setSbirRows,
  setClosedSbirRows,
  clearRfpData,
  setSelectedFile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RFPSearchAndManagementPage)
